import React from 'react'
import { AppState } from 'state/store'
import Header from 'components/Header'
import InfoPopup from 'components/InfoPopup'
import './App.css'
import Content from 'components/Content'

// interface Question {
//   groupIndex: number,
//   questionIndex: number,
// }

/**
 * All questions of all groups flattened to a list of questions identified by group and question index.
 */
// const questions: Question[] = questionGroups.map(
//   (group, groupIndex) => group.questions.map(
//     (question, questionIndex) => ({
//       groupIndex,
//       questionIndex,
//     })
//   )
// ).flat()

// function renderQuestionViews(questionnaireState: QuestionnaireState, lang: LanguageCode) {

//   const indexOfFirstUnansweredQuestion = getIndexOfFirstUnansweredQuestion(questionnaireState)

//   const questionsToRender = indexOfFirstUnansweredQuestion === null ? (
//     // All questions have to be rendered
//     questions
//   ) : (
//     // Just all answered questions plus the first unanswered one have to be rendered (can also be all questions)
//     take(1 + indexOfFirstUnansweredQuestion, questions)
//   )

//   return questionsToRender.map((question, index) => (
//     <QuestionView
//       questionGroupIndex={question.groupIndex}
//       questionIndex={question.questionIndex}
//       selectedChoiceIndex={questionnaireState[question.groupIndex][question.questionIndex]}
//       lang={lang}
//     />
//   ))
// }

function App(props: {
  state: AppState, // TODO Nur state, der für die UI wichtig ist, verlangen... Gibt es anderen?
}) {
  const state = props.state

  const showInfoPopup = false
    // !state.navigation.infoPopupClosed
    // && state.navigation.selectedViewIndex === 0 
    // && getIndexOfFirstUnansweredQuestion(state.questionnaire) === 0

  return (
    <div className="App">
      <Header
        isMenuOpen={state.menu.isOpen}
        lang={state.language.selectedLanguage}
      />
      <Content
        selectedViewIndex={state.navigation.selectedViewIndex}
        lang={state.language.selectedLanguage}
        questionnaireState={state.questionnaire}
        feedbackState={state.feedback}
      />
      {showInfoPopup && (
        <InfoPopup 
          lang={state.language.selectedLanguage}
        />
      )}
    </div>
  )
}

export default App
