import React from 'react'
import ViewWrapper from "components/ViewWrapper"
import { TextField, Fab, FormControlLabel, Checkbox } from '@material-ui/core'
import './Feedback.css'
import {
  FeedbackState, 
  isFeedbackComplete, 
  isFeedbackInTransit, 
  isFeedbackConsentGiven, 
  isFeedbackSent,
  hasValidEmail,
} from 'state/feedback'
import { createChangeFeedbackCommentAction } from './actions/ChangeFeedbackCommentAction'
import { createChangeFeedbackConsentAction } from './actions/ChangeFeedbackConsentAction'
import { createClickSubmitFeedbackButtonAction } from './actions/ClickSubmitFeedbackButtonAction'
import { LanguageCode } from 'modules/MultiLang'
import { feedbackConfig } from 'config/AppConfig'
import { FeedbackConfig } from './FeedbackConfig'
import { store } from 'state/store'

const config: FeedbackConfig = feedbackConfig

// TODO: Split into more sub components(?)

function Feedback(props: {
  state: FeedbackState,
  lang: LanguageCode,
}) {
  const { state, lang } = props

  if (isFeedbackSent(state)) {
    return (
      <ViewWrapper heading={config.heading[lang]}>
        {config.thankYouMessage.map((paragraph, index) => (
          <p key={index}>{paragraph[lang]}</p>
        ))}
      </ViewWrapper>
    )
  }

  const isSubmitButtonEnabled = (
    isFeedbackComplete(state)
  ) && (
    !isFeedbackInTransit(state)
  ) && (
    isFeedbackConsentGiven(state)
  )

  // TODO: Solange Feedback "in transit" ist, Formularelemente deaktivieren?

  return (
    <ViewWrapper 
      heading={config.heading[lang]}
      info={config.info[lang]}
    >
      <div className="commentFieldContainer">
        <TextField 
          type="email"
          error={state.comment !== undefined && !hasValidEmail(state)}
          fullWidth
          variant="outlined"
          value={state.comment === undefined ? '' : state.comment} 
          label={config.commentFieldLabel[lang]} 
          onChange={
            (event: React.ChangeEvent<HTMLTextAreaElement>) => 
              store.dispatch(createChangeFeedbackCommentAction(event.target.value))
          }
        />
      </div>
      <div className="dataRetentionConsentCheckBoxContainer">
        <FormControlLabel
          control={
            <Checkbox
              checked={isFeedbackConsentGiven(state)}
              onChange={() => store.dispatch(createChangeFeedbackConsentAction())}
              name="checkedB"
              color="secondary"
            />
          }
          label={config.dataRetentionConsentCheckBoxLabel[lang]}
        />
      </div>
      <div className="feedbackSubmitButtonContainer">
        <Fab 
          onClick={() => store.dispatch(createClickSubmitFeedbackButtonAction())} 
          disabled={!isSubmitButtonEnabled} 
          variant="extended"
        >
          {config.submitButtonText[lang]}
        </Fab>
      </div>
    </ViewWrapper>
  )
}

export default Feedback
