import React from 'react'
import './Header.css'
import Logo from 'components/Logo'
import LanguageToggle from 'components/LanguageToggle'
import MainMenu from 'components/MainMenu'
import { LanguageCode } from 'modules/MultiLang'

function Header(props: {
  isMenuOpen: boolean,
  lang: LanguageCode,
}) {
  return (
    <header className="Header">
      <Logo />
      <LanguageToggle
        lang={props.lang}
      />
      <MainMenu 
        isMenuOpen={props.isMenuOpen}
        lang={props.lang}
      />
    </header>
  )
}

export default React.memo(Header)
