import React, { ReactNode } from 'react'
import InfoBox from 'components/InfoBox'
import './ViewWrapper.css'

function ViewWrapper(props: {
  heading?: string,
  info?: string,
  children?: ReactNode
}) {
  return (
    <div className="ViewWrapper">
      {props.heading !== undefined &&
        <h2>{props.heading}</h2>
      }
      {props.info !== undefined && 
        <InfoBox info={props.info} />
      }
      {props.children}
    </div>
  )
}

export default ViewWrapper
