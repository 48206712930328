import { Middleware } from 'redux'
import { AppState } from 'state/store'
import { 
  isChangeFeedbackConsentAction,
  isClickSubmitFeedbackButtonAction,
  isChangeFeedbackCommentAction,
} from 'components/Feedback'
import { 
  createSetFeedbackConsentAction,
  createSetFeedbackCommentAction,
  createSetFeedbackInTransitAction,
  createSetFeedbackSentAction,
} from 'state/feedback'
import { feedbackApiUrl } from 'config/AppConfig'
import { createFeedbackSuccessAction, isFeedbackSuccessAction } from './actions/FeedbackSuccessAction'
import { createFeedbackFailureAction, isFeedbackFailureAction } from './actions/FeedbackFailureAction'
import { QuestionnaireState } from 'state/questionnaire'



const feedbackMiddleware: Middleware<{}, AppState> = middlewareAPI => next => action => {

  const returnedAction = next(action)
  
  if (isChangeFeedbackCommentAction(action)) {
    middlewareAPI.dispatch(createSetFeedbackCommentAction(action.payload.newText))

  } else if (isChangeFeedbackConsentAction(action)) {
    const currentConsent = middlewareAPI.getState().feedback.consent
    middlewareAPI.dispatch(createSetFeedbackConsentAction(!currentConsent))

  } else if (isClickSubmitFeedbackButtonAction(action)) {
    const state = middlewareAPI.getState()
    // TODO really check email for validity
    const request = createFeedbackRequest(state.questionnaire, state.feedback.comment as string)

    // TODO: Let this be handled by a more generic fetch middleware?
    fetch(request)
      // .then(() => middlewareAPI.dispatch(createFeedbackSuccessAction()))
      .then(response => response.text())
      .then(responseText => {
        console.log(responseText)
        middlewareAPI.dispatch(createFeedbackSuccessAction())
      })
      // .catch(error => middlewareAPI.dispatch(createFeedbackFailureAction(error)))
      .catch(error => {
        console.log('Error while trying to send feedback!');
        console.dir(error);
        middlewareAPI.dispatch(createFeedbackFailureAction(error)) // TODO: error wird kein string sein!
      }) // TODO: Was ist error genau?
    
      middlewareAPI.dispatch(createSetFeedbackInTransitAction(true))
      // TODO: Die verschiedenen möglichen Fehlerfälle überlegen und sinnvoll reagieren!

  } else if (isFeedbackSuccessAction(action)) {
    middlewareAPI.dispatch(createSetFeedbackInTransitAction(false))
    middlewareAPI.dispatch(createSetFeedbackSentAction(true))

  } else if (isFeedbackFailureAction(action)) {
    middlewareAPI.dispatch(createSetFeedbackInTransitAction(false))
    // TODO: Display modal with error message

  }

  return returnedAction
}

const createFeedbackRequest = (questionnaireState: QuestionnaireState, email: string): Request => 
  new Request(
    feedbackApiUrl,
    {
      method: 'POST',
      body: JSON.stringify({
        choices: questionnaireState,
        email,
      }),
    }
  )

export default feedbackMiddleware
