import { Action } from "redux"
import { isAction } from 'modules/ActionTypeGuard'

const SET_QUESTIONNAIRE_CHOICE_ACTION = '[STATE] set idea evaluation choice';

export interface SetQuestionnaireChoiceAction extends Action<typeof SET_QUESTIONNAIRE_CHOICE_ACTION> {
  payload: {
    groupId: number,
    questionId: number,
    choice: number,
  }
}

export const isSetQuestionnaireChoiceAction = (x: any): x is SetQuestionnaireChoiceAction =>
  isAction(x) && x.type === SET_QUESTIONNAIRE_CHOICE_ACTION

export const createSetQuestionnaireChoiceAction = (
  groupId: number,
  questionId: number,
  choice: number,
): SetQuestionnaireChoiceAction => ({
  type: SET_QUESTIONNAIRE_CHOICE_ACTION,
  payload: {
    groupId,
    questionId,
    choice,
  }
})
