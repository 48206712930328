import produce from 'immer'
import { FeedbackState } from "./FeedbackState"
import { isSetFeedbackCommentAction } from "./actions/SetFeedbackCommentAction"
import { isSetFeedbackConsentAction } from "./actions/SetFeedbackConsentAction"
import { isSetFeedbackInTransitAction } from "./actions/SetFeedbackInTransitAction"
import { isSetFeedbackSentAction } from "./actions/SetFeedbackSentAction"

const initialFeedbackState: FeedbackState = {
  comment: undefined,
  consent: false,
  inTransit: false,
  sent: false,
}

export const feedbackReducer = produce((draft: FeedbackState, action: any): void => {

  if (isSetFeedbackCommentAction(action)) {
    draft.comment = action.payload.comment

  } else if (isSetFeedbackConsentAction(action)) {
    draft.consent = action.payload.consent

  } else if (isSetFeedbackInTransitAction(action)) {
    draft.inTransit = action.payload.inTransit

  } else if (isSetFeedbackSentAction(action)) {
    draft.sent = action.payload.sent

  }
}, initialFeedbackState)
