import React, { ReactNode } from 'react'
import { store } from 'state/store'
import SwipeableViews from 'react-swipeable-views';
import { createSwipeViewAction } from './actions/SwipeViewAction'
import { pipe, take } from 'ramda';
import NavBar from 'components/NavBar';
import { getIndexOfFirstIncompleteQuestionGroup, isQuestionnaireCompleted, QuestionnaireState } from 'state/questionnaire';
import { LanguageCode } from 'modules/MultiLang';
import { questionGroups } from 'config/AppConfig';
import ViewWrapper from 'components/ViewWrapper';
import Question from 'components/Question';
import { FeedbackState } from 'state/feedback';
import Intro from 'components/Intro';
import Feedback from 'components/Feedback';
import './Content.css';

function renderQuestionGroupViews(questionnaireState: QuestionnaireState, lang: LanguageCode) {

  const indexOfFirstIncompleteQuestionGroup = getIndexOfFirstIncompleteQuestionGroup(questionnaireState)

  const groupsToRender = indexOfFirstIncompleteQuestionGroup === null ? (
    // All questions have to be rendered
    questionGroups
  ) : (
    // Just all answered questions plus the first unanswered one have to be rendered (can also be all questions)
    take(1 + indexOfFirstIncompleteQuestionGroup, questionGroups)
  )

  return groupsToRender.map((group, groupIndex) => (
    <ViewWrapper>
      <h2>
        {group.title[lang]}
      </h2>
      {
        group.questions.map((question, questionIndex) => (
          <Question
            questionGroupIndex={groupIndex}
            questionIndex={questionIndex}
            selectedChoiceIndex={questionnaireState[groupIndex][questionIndex]}
            lang={lang}
            key={questionIndex}
          />
        ))
      }
    </ViewWrapper>
  ))
}

function renderViews(
  questionnaireState: QuestionnaireState,
  feedbackState: FeedbackState,
  lang: LanguageCode,
) {
  const introView = <Intro lang={lang} />

  const questionnaireViews = renderQuestionGroupViews(questionnaireState, lang)
  // const questionnaireViews = renderQuestionViews(questionnaireState, lang)

  const feedbackViews = isQuestionnaireCompleted(questionnaireState) ? (
    [ <Feedback state={feedbackState} lang={lang} /> ]
  ) : (
    []
  )

  return [introView, questionnaireViews, feedbackViews].flat()
}

function Content(props: {
  selectedViewIndex: number,
  lang: LanguageCode,
  questionnaireState: QuestionnaireState,
  feedbackState: FeedbackState,
  children?: ReactNode[],
}) {
  const changeIndexCallback = pipe(
    createSwipeViewAction,
    store.dispatch
  )

  const renderedViews = renderViews(
    props.questionnaireState,
    props.feedbackState,
    props.lang,
  )

  const maxAllowedViewIndex = renderedViews.length - 1
  // TODO Diese Einschränkung muss auch bei State-Updates geprüft werden. Sollte diesbezüglicher Code also nicht 
  // irgendwo zentral liegen?

  return (
    <main className="Content">
      <SwipeableViews
        className="SwipeableViews"
        index={props.selectedViewIndex} 
        onChangeIndex={changeIndexCallback}
        // enableMouseEvents
        resistance
      >
        {renderedViews}
      </SwipeableViews>
      <NavBar
        selectedViewIndex={props.selectedViewIndex}
        maxAllowedViewIndex={maxAllowedViewIndex}
        lang={props.lang}
      />
    </main>
  )
}

export default Content
