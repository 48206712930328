import React from 'react'
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import './MultipleChoiceQuestion.css'

function MultipleChoiceQuestion(props: {
  question: string,
  choices: string[],
  selectedChoice: number|undefined,
  selectionHandler: (event: React.ChangeEvent<HTMLInputElement>) => void,
}) {
  return (
    <div className="MultipleChoiceQuestion">
      <p className="question-text">{props.question}</p>
      <FormControl component="fieldset">
        {/* <FormLabel component="legend">
          {props.question}
        </FormLabel> */}
        <RadioGroup
          aria-label="question"
          name="question1"
          value={props.selectedChoice === undefined ? '' : props.selectedChoice}
          // Do not pass undefined to value initially or the component will be uncontrolled (by React)
          // onChange={(event) => props.handleChoice(props.questionGroupId, props.questionId, parseInt(event.target.value))}
          onChange={props.selectionHandler}
        >
          {props.choices.map((choice, index) =>
            <FormControlLabel
              key={index} 
              value={index} 
              control={
                <Radio color="primary" />
              } 
              label={choice}
            />
          )}
        </RadioGroup>
      </FormControl>
    </div>
  )
}

export default MultipleChoiceQuestion
