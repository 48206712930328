import React from 'react'
import { store } from 'state/store'
import { createQuestionChoiceAction } from './actions/QuestionChoiceAction'
import MultipleChoiceQuestion from 'components/MultipleChoiceQuestion'
import { LanguageCode } from 'modules/MultiLang'
import { questionGroups } from 'config/AppConfig'
import './Question.css'

function Question(props: {
  questionGroupIndex: number,
  questionIndex: number,
  selectedChoiceIndex: number|undefined,
  lang: LanguageCode,
}) {
  const question = questionGroups[props.questionGroupIndex].questions[props.questionIndex]
  const questionText = question.question[props.lang]
  const choices = question.choices.map(multiLangChoice => multiLangChoice[props.lang])

  return (
    <div className="Question">
      <MultipleChoiceQuestion
        question={questionText}
        choices={choices}
        selectedChoice={props.selectedChoiceIndex}
        selectionHandler={(event) => store.dispatch(
          createQuestionChoiceAction(
            props.questionGroupIndex, 
            props.questionIndex, 
            parseInt(event.target.value)
          )
        )}
      />
    </div>
  )
}

export default React.memo(Question)
