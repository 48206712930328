import produce, { Draft } from 'immer'
import { QuestionnaireState } from './QuestionnaireState'
import { isSetQuestionnaireChoiceAction } from './actions/SetQuestionnaireChoiceAction'
import { questionGroups } from 'config/AppConfig';

function createInitialState(): QuestionnaireState {
  return questionGroups.map(questionGroup =>
    questionGroup.questions.map(() => undefined)
  )
}

const initialQuestionnaireState = createInitialState()

const questionnaireReducer = produce((draft: Draft<QuestionnaireState>, action: any): void => {
  if (isSetQuestionnaireChoiceAction(action)) {
    draft[action.payload.groupId][action.payload.questionId] = action.payload.choice
  }
}, initialQuestionnaireState)

export default questionnaireReducer
