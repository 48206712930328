import React from 'react'
import { introConfig as config } from 'config/AppConfig'
import { LanguageCode } from 'modules/MultiLang'
import ViewWrapper from 'components/ViewWrapper'

function Intro(props: {
  lang: LanguageCode
}) {
  return (
    <ViewWrapper
      heading={config.heading[props.lang]}
    >
      <p>
        {config.paragraph1[props.lang]}
      </p>
      <ul>
        {
          config.listItems.map((item, index) => (
            <li key={index}>
              {item[props.lang]}
            </li>
          ))
        }
      </ul>
      <p>
        {config.paragraph2[props.lang]}
      </p>
      <p>
        {config.paragraph3[props.lang]}
      </p>
    </ViewWrapper>
  )
}

export default Intro
