export interface FeedbackState {
  comment: string|undefined,
  consent: boolean,
  inTransit: boolean,
  sent: boolean,
}

const emailRegExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
// Src: https://developer.mozilla.org/en-US/docs/Learn/Forms/Form_validation

export function hasValidEmail(state: FeedbackState) {
  return state.comment !== undefined && emailRegExp.test(state.comment)
}

export function isFeedbackComplete(state: FeedbackState) {
  return hasValidEmail(state)
}

export function isFeedbackInTransit(state: FeedbackState) {
  return state.inTransit
}

export function isFeedbackConsentGiven(state: FeedbackState) {
  return state.consent
}

export function isFeedbackSent(state: FeedbackState) {
  return state.sent
}
