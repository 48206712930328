import { Middleware } from 'redux'
import { isQuestionViewChoiceAction } from 'components/QuestionView';
import {
  createSetQuestionnaireChoiceAction,
  isQuestionAnswered
} from 'state/questionnaire'
import { createNextViewAction } from 'middleware/navigationMiddleware';
import { AppState } from 'state/store';
import { isQuestionChoiceAction } from 'components/Question';

const questionnaireMiddleware: Middleware<{}, AppState> = middlewareAPI => next => action => {
  const returnedAction = next(action)
  
  if (isQuestionViewChoiceAction(action)) {

    const isAlreadyAnswered = isQuestionAnswered(
      action.payload.questionGroupId, 
      action.payload.questionId,
      middlewareAPI.getState().questionnaire
    );

    // Dispatch Action to update the state
    middlewareAPI.dispatch(createSetQuestionnaireChoiceAction(
      action.payload.questionGroupId,
      action.payload.questionId,
      action.payload.selectedChoiceId
    ))

    // If question was previously unanswered, initiate navigation to next view.
    if (!isAlreadyAnswered) {
      middlewareAPI.dispatch(createNextViewAction())
    }
  }
  
  if (isQuestionChoiceAction(action)) {

    middlewareAPI.dispatch(createSetQuestionnaireChoiceAction(
      action.payload.questionGroupId,
      action.payload.questionId,
      action.payload.selectedChoiceId
    ))
  }
  
  return returnedAction
}

export default questionnaireMiddleware
