/**
 * Stellt die gesamten statischen Daten für die App zur Verfügung. Diese sind in der zentralen config.json abgelegt und
 * werden von diesem Modul in der Form aufbereitet und zur Verfügung gestellt, wie die Komponenten/Module sie benötigen.
 * 
 * Begründung für diese Lösung: Eine zentrale App-Config-Datei ist gut versendbar und für Nicht-Entwickler bearbeitbar.
 */

import configJSON from 'config.json';
import { FeedbackConfig } from 'components/Feedback';
import { InfoPopupConfig } from 'components/InfoPopup';
import { LanguageToggleConfig } from 'components/LanguageToggle';
import { MainMenuConfig } from 'components/MainMenu';
import { NavBarConfig } from 'components/NavBar';
import { MultiLangString } from 'modules/MultiLang';
import { toQuestionnaireWithChoicesOnlyAttachedToQuestions, ValidQuestionnaire } from './Questionnaire';
import { IntroConfig } from 'components/Intro';

interface AppConfig {
  languageToggle: LanguageToggleConfig,
  mainMenu: MainMenuConfig,
  navBar: NavBarConfig,
  intro: IntroConfig,
  infoPopup: InfoPopupConfig,
  questionnaire: ValidQuestionnaire<MultiLangString>,
  feedback: FeedbackConfig,
}

const config: AppConfig = configJSON

export const languageToggle: LanguageToggleConfig = config.languageToggle
export const mainMenu: MainMenuConfig = config.mainMenu
export const navBar: NavBarConfig = config.navBar
export const introConfig: IntroConfig = config.intro
export const infoPopup: InfoPopupConfig = config.infoPopup

export const questionGroups = toQuestionnaireWithChoicesOnlyAttachedToQuestions(config.questionnaire).questionGroups
// intro view + number of question groups + submission view
export const viewCount = 1 + questionGroups.length + 1
// TODO This depends on structure of UI, put this into App.tsx?

export const feedbackConfig: FeedbackConfig = config.feedback

export const feedbackApiUrl = config.feedback.apiURL
