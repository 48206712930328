import React from 'react'
import { store } from 'state/store'
import { createLogoClickAction } from './actions/LogoClickAction'
import './Logo.css'
import logo from './logo.svg'

function Logo() {
  return (
    <div className="Logo">
      <img onClick={() => store.dispatch(createLogoClickAction())} src={logo} alt="Logo" />
    </div>
  )
}

export default Logo
