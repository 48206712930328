export type QuestionnaireState = readonly (readonly (number | undefined)[])[]

export function isQuestionnaireCompleted(state: QuestionnaireState) {
  return state.flat().every(value => value !== undefined)
}

function min(x: number, y: number) {
  return x <= y ? x : y
}

function minimum(xs: number[]) {
  return xs.reduce(min)
}

/**
 * Get index of first unanswered question.
 * 
 * @param state 
 * @return Index of the first unanswered question or null, if all have been answered
 */
export function getIndexOfFirstUnansweredQuestion(state: QuestionnaireState) {
  // Ersetze die unbeantworteten Einträge mit ihrem Index, die bereits beantworteten mit POSITIVE_INFINITY
  const questionIndexes = state.flat().map((item, index) => item === undefined ? index : Number.POSITIVE_INFINITY);
  // Der Index der ersten unbeantworteten Frage ist nun der niedrigste Eintrag
  const minIndex = minimum(questionIndexes);
  // Transform POSITIVE_INFINITY to null if necessary
  return minIndex === Number.POSITIVE_INFINITY ? null : minIndex
}
// TODO This could be made independent of 'question' context

export function isQuestionAnswered(questionGroupId: number, questionId: number, state: QuestionnaireState): boolean {
  return state[questionGroupId][questionId] !== undefined
}

export function getIndexOfFirstIncompleteQuestionGroup(state: QuestionnaireState) {
  const groupIndexes = state.map(
    (group, index) => group.some(value => value === undefined)
      ? index
      : Number.POSITIVE_INFINITY
  )
  const minIndex = minimum(groupIndexes);
  return minIndex === Number.POSITIVE_INFINITY ? null : minIndex
}
