interface Question<T> {
  question: T,
  choices?: T[]
}

interface QuestionGroup<T> {
  title: T,
  questions: Question<T>[]
  choices?: T[]
}

interface Questionnaire<T> {
  questionGroups: QuestionGroup<T>[]
  choices?: T[]
}

type QuestionWithChoices<T> = Required<Question<T>>
type QuestionGroupWithChoices<T> = Required<QuestionGroup<T>>
type QuestionnaireWithChoices<T> = Required<Questionnaire<T>>

// type QuestionWithoutChoices<T> = Omit<Question<T>, 'choices'>
// type QuestionGroupWithoutChoices<T> = Omit<QuestionGroup<T>, 'choices'>
// type QuestionnaireWithoutChoices<T> = Omit<Questionnaire<T>, 'choices'>



function isQuestionWithChoices<T>(question: Question<T>): question is QuestionWithChoices<T> {
  return 'choices' in question
}

// function isQuestionWithoutChoices<T>(question: Question<T>): question is QuestionWithoutChoices<T> {
//   return !isQuestionWithChoices(question)
// }

function isQuestionGroupWithChoices<T>(questionGroup: QuestionGroup<T>): 
  questionGroup is QuestionGroupWithChoices<T> 
{
  return 'choices' in questionGroup
}

// function isQuestionGroupWithoutChoices<T>(questionGroup: QuestionGroup<T>): 
//   questionGroup is QuestionGroupWithoutChoices<T> 
// {
//   return !isQuestionGroupWithChoices(questionGroup)
// }

// function isQuestionnaireWithChoices<T>(questionnaire: Questionnaire<T>): 
//   questionnaire is QuestionnaireWithChoices<T> 
// {
//   return 'choices' in questionnaire
// }

// function isQuestionnaireWithoutChoices<T>(questionnaire: Questionnaire<T>): 
//   questionnaire is QuestionnaireWithoutChoices<T> 
// {
//   return !isQuestionnaireWithChoices(questionnaire)
// }



// A valid questionnaire has for every question (leaf) at least one choices property on the path from root to leaf
type ValidQuestionnaire<T>
  = QuestionnaireWithChoices<T>
  | { // QuestionnaireWithoutChoices<T>
      questionGroups: Array<
        | QuestionGroupWithChoices<T>
        | { // QuestionGroupWithoutChoices<T>
            title: T,
            questions: Array<QuestionWithChoices<T>>
          }
      >
    }

interface QuestionnaireWithChoicesOnlyAttachedToQuestions<T> {
  questionGroups: {
    title: T,
    questions: QuestionWithChoices<T>[]
  }[]
}



// Functions

function toQuestionnaireWithChoicesOnlyAttachedToQuestions<T>(questionnaire: ValidQuestionnaire<T>) {
  return {
    questionGroups: (questionnaire.questionGroups as QuestionGroup<T>[]).map(questionGroup => ({
      title: questionGroup.title,
      questions: questionGroup.questions.map(question => ({
        question: question.question,
        choices: isQuestionWithChoices(question)
          ? question.choices
          : isQuestionGroupWithChoices(questionGroup)
            ? questionGroup.choices
            : (questionnaire as QuestionnaireWithChoices<T>).choices
              // The type ValidQuestionnaire should guarantee that when the question and group have no choices, the
              // questionnaire has one
              // TODO Wie kann man dies durch das Typsystem (ohne Cast zu benötigen) sicherstellen?
      }))
    }))
  }
}

// function toQuestionnaireWithChoicesOnlyAttachedToQuestions<T>(questionnaire: ValidQuestionnaire<T>) {
//   return {
//     questionGroups: (questionnaire.questionGroups as QuestionGroup<T>[]).map(questionGroup => ({
//       title: questionGroup.title,
//       questions: questionGroup.questions.map(question => ({
//         question: question.question,
//         choices: isQuestionWithChoices(question)
//           ? question.choices
//           : isQuestionGroupWithChoices(questionGroup)
//             ? questionGroup.choices
//             : isQuestionnaireWithChoices(questionnaire)
//               ? questionnaire.choices
//               : null // TODO Wie Typsystem beibringen, dass diese Möglichkeit nicht auftreten kann?
//       }))
//     }))
//   }
// }

// function toQuestionnaireWithChoicesOnlyAttachedToQuestions<T>(questionnaire: ValidQuestionnaire<T>) {
//   if (isQuestionnaireWithChoices(questionnaire)) {
//     return {
//       questionGroups: questionnaire.questionGroups.map(questionGroup => {
//         if (isQuestionGroupWithChoices(questionGroup)) {
//           return {
//             title: questionGroup.title,
//             questions: questionGroup.questions.map(question => {
//               if (isQuestionWithChoices(question)) {
//                 return question
//               } else {
//                 return {
//                   ...question,
//                   choices: questionGroup.choices
//                 }
//               }
//             })
//           }
//         } else {

//         }
//       })
//     }
//   } else {

//   }
// }

// function getQuestionGroupTitle<T>(questionnaire: ValidQuestionnaire<T>, index: number) {
//   return questionnaire.questionGroups[index].title
// }

// function getChoicesForQuestion<T>(
//   questionnaire: ValidQuestionnaire<T>,
//   questionGroupIndex: number,
//   questionIndex: number
// ) {
//   const questionGroup = questionnaire.questionGroups[questionGroupIndex]
//   const question = questionGroup.questions[questionIndex]

//   if (isQuestionWithChoices(question)) {
//     return question.choices
//   }

//   if (isQuestionGroupWithChoices(questionGroup)) {
//     return questionGroup.choices
//   }

//   if (isQuestionnaireWithChoices(questionnaire)) {
//     return questionnaire.choices
//   }

//   // TODO Wie Typsystem beibringen, dass dies schon alle Möglichkeiten waren?
// }

// function getQuestion<T>(
//   questionnaire: ValidQuestionnaire<T>,
//   questionGroupIndex: number,
//   questionIndex: number
// ) {
//   const questionGroup = questionnaire.questionGroups[questionGroupIndex]
//   const question = questionGroup.questions[questionIndex]

//   if (isQuestionWithChoices(question)) {
//     return question
//   }

//   if (isQuestionGroupWithChoices(questionGroup)) {
//     return {
//       ...question,
//       choices: questionGroup.choices
//     }
//   }

//   if (isQuestionnaireWithChoices(questionnaire)) {
//     return {
//       ...question,
//       choices: questionnaire.choices
//     }
//   }

//   // TODO Wie Typsystem beibringen, dass dies schon alle Möglichkeiten waren?
//   // TODO Sollte die Transformation nur einmal zum Start (oder gar zur Kompilierzeit) geschehen?
// }

export type {
  ValidQuestionnaire,
  QuestionnaireWithChoicesOnlyAttachedToQuestions
}
export { toQuestionnaireWithChoicesOnlyAttachedToQuestions }
