import { Action } from "redux"
import { isAction } from 'modules/ActionTypeGuard'

const QUESTION_CHOICE_ACTION = '[UI] question choice'

export interface QuestionChoiceAction extends Action<typeof QUESTION_CHOICE_ACTION> {
  payload: {
    questionGroupId: number,
    questionId: number,
    selectedChoiceId: number
  }
}

export const isQuestionChoiceAction = (x: any): x is QuestionChoiceAction => 
  isAction(x) && x.type === QUESTION_CHOICE_ACTION

export const createQuestionChoiceAction = (
  questionGroupId: number,
  questionId: number,
  selectedChoiceId: number
): QuestionChoiceAction => ({
  type: QUESTION_CHOICE_ACTION,
  payload: {
    questionGroupId,
    questionId,
    selectedChoiceId,
  }
})
