import { Action } from "redux"
import { isAction } from 'modules/ActionTypeGuard'

const QUESTION_VIEW_CHOICE_ACTION = '[UI] question view choice'

export interface QuestionViewChoiceAction extends Action<typeof QUESTION_VIEW_CHOICE_ACTION> {
  payload: {
    questionGroupId: number,
    questionId: number,
    selectedChoiceId: number
  }
}

export const isQuestionViewChoiceAction = (x: any): x is QuestionViewChoiceAction => 
  isAction(x) && x.type === QUESTION_VIEW_CHOICE_ACTION

export const createQuestionViewChoiceAction = (
  questionGroupId: number,
  questionId: number,
  selectedChoiceId: number
): QuestionViewChoiceAction => ({
  type: QUESTION_VIEW_CHOICE_ACTION,
  payload: {
    questionGroupId,
    questionId,
    selectedChoiceId,
  }
})
