import React from 'react'
import MobileStepper from '@material-ui/core/MobileStepper'
import Button from '@material-ui/core/Button'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { store } from 'state/store'
import { createNextButtonClickAction } from './actions/NextButtonClickAction'
import { createBackButtonClickAction } from './actions/BackButtonClickAction'
import { LanguageCode } from 'modules/MultiLang'
import { viewCount, navBar } from 'config/AppConfig'
import { NavBarConfig } from './NavBarConfig'
import './NavBar.css'

const config: NavBarConfig = navBar

function NavBar(props: {
  selectedViewIndex: number,
  maxAllowedViewIndex: number,
  lang: LanguageCode,
}) {
  const {
    selectedViewIndex,
    maxAllowedViewIndex,
    lang
  } = props;

  const nextButtonEnabled = selectedViewIndex < maxAllowedViewIndex
  const backButtonEnabled = selectedViewIndex > 0

  return (
    <MobileStepper
      className="NavBar"
      variant="text"
      steps={viewCount}
      position="static"
      activeStep={selectedViewIndex}
      nextButton={
        <Button 
          className="nextButton"
          endIcon={<ArrowForwardIcon />}
          onClick={() => store.dispatch(createNextButtonClickAction())}
          disabled={!nextButtonEnabled}
        >
          {config.nextButtonLabel[lang]}
        </Button>
      }
      backButton={
        <Button
          className="prevButton"
          startIcon={<ArrowBackIcon />}
          onClick={() => store.dispatch(createBackButtonClickAction())}
          disabled={!backButtonEnabled}
        >
          {config.backButtonLabel[lang]}
        </Button>
      }
    />
  )
}

export default React.memo(NavBar)
